.legend {
  display: flex;
  flex-direction: column;
  padding: 12px; /*was 10px initially*/
  justify-content: center;
  align-items: center;
  background-color: white;
}

.legend-palette {
  display: flex;
  justify-content: center;
  margin-left: "10px";
  margin-right: 5%;
  width: 210px;
}

.legend .legend-block {
  display: inline-block;
  height: 8px;
  flex: 1;
  font-size: 8px;
  position: relative;
}

.legend .unit-block {
  flex: 1;
  padding-left: 5px;
}

.legend .legend-label {
  position: absolute;
  top: -12px;
  font-size: 8px;
  font-family: "Roboto";
  font-weight: 900;
  margin-left: 2%;
}

.legend .legend-downlabel {
  position: relative;
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 900;
}

.legend .unit-wrapper {
  position: relative;
  padding-right: 2px;
}

.legend .unit {
  font-size: 9px;
  position: absolute;
  bottom: 12px;
  right: -17px;
}

.legend .legend-pictures {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10%;
  margin-right: 10%;
}

.legend .legend-pictures div {
  transform: rotate(90deg);
}

.MuiCard-root {
  height: 210px !important;
}

.legend-label {
  color: black !important;
}
